<template>
  <div>
    <div v-show="showIndex==0">
      <CRow>
        <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-star mb-3t">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.positioningList')}`}} </h6>
        </CCol>
      </CRow>
      <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
        <CTab :title="$t('label.packaging')" v-if="PackagingJson?.length>0 || !(ContainerJson?.length>0)">
          <CRow class="mt-3 m-0">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
              <h6 class="text-warning mr-3 mt-2">
                <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.currentPosition')}}&nbsp;
              </h6> 
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center ml-auto"
                v-c-tooltip="{
                  content: $t('label.containerPositioning'),
                  placement: 'top-end'
                }"
                @click="showIndex=1"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol> 
          </CRow>
          <CRow class="m-0">
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                details
                column-filter
                :items="formatedItems"
                :fields="fields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
                pagination
              >
                <template #alertGif="{ item }">
                  <td class="center-cell">
                    <div class="animation-item ml-2" style="display: inline-block;" v-if="item.ComputedFinishDate=='' && item.Status == 'ACTIVO'">
                      <h3 class="text-danger">
                          <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                      </h3>
                    </div>
                  </td>
                </template>
                <template #Status="{item}">
                  <td class='text-center'>
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #button="{item}">
                  <td class="text-center">
                    <CButton
                      color="edit"
                      square
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.editContainerPositioning'),
                        placement: 'top-start'
                      }"
                      @click="toggleAdd(item)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                    <CButton
                      v-if="item.Seal1 || item.Seal2 || item.Seal3 || item.Seal4"
                      style="background-color: #d6d6d7;"
                      square
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.Seals'),
                        placement: 'top-start'
                      }"
                      @click="viewDetail(item)"
                    >
                      <CIcon name="bottomSeals"/>
                    </CButton>
                  </td>
                </template>
                <template #details="{item}">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <dataTableExtended
                        class="align-center-row-datatable"
                        small
                        :items="formatedSeal(item)"
                        :fields="level2Fields"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="tableText.itemsPerPage"
                      >
                      </dataTableExtended>
                    </CCardBody>
                  </CCollapse>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.Containers')" v-if="ContainerJson?.length>0">
          <CRow class="mt-3">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
              <h6 class="text-warning mr-3 mt-2">
                <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>{{$t('label.currentPosition')}}&nbsp;
              </h6> 
              <CButton
                shape="square"
                color="add"
                class="d-flex align-items-center ml-auto"
                v-c-tooltip="{
                  content: $t('label.containerPositioning'),
                  placement: 'top-end'
                }"
                @click="showIndex=2"
              >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
              </CButton>
            </CCol> 
          </CRow>
          <CRow>
            <CCol col="12" xl="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                details
                column-filter
                :items="ConatinerItems"
                :fields="Containerfields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
                pagination
              >
                <template #alertGif="{ item }">
                  <td class="center-cell">
                    <div class="animation-item ml-2" style="display: inline-block;" v-if="item.ComputedFinishDate=='' && item.Status == 'ACTIVO'">
                      <h3 class="text-danger">
                        <CIcon name='alertas' color="danger" style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                      </h3>
                    </div>
                  </td>
                </template>
                <template #Status="{item}">
                  <td class='text-center'>
                    <CBadge :color="getBadge(item.Status)">
                      {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #button="{item}">
                  <td class="text-center">
                    <CButton
                      color="edit"
                      square
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.editContainerPositioning'),
                        placement: 'top-start'
                      }"
                      @click="toggleContainerAdd(item)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                    <CButton
                      style="background-color: #d6d6d7;"
                      square
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.Seals'),
                        placement: 'top-start'
                      }"
                      @click="viewDetail(item)"
                    >
                      <CIcon name="bottomSeals"/>
                    </CButton>
                  </td>
                </template>
                <template #details="{item}">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <dataTableExtended
                        class="align-center-row-datatable"
                        small
                        :items="formatedSeal(item.SealJson)"
                        :fields="level2Fields"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="tableText.itemsPerPage"
                      >
                        <template #button="{item}">
                          <td class="text-center">
                            <CButton 
                              v-if="item.SealDocumentJson.length!=0&&item.Seal"
                              color="watch"
                              square
                              size="sm"
                              class="mr-1"
                              @click="ModalImagen(item)"
                            >
                              <CIcon name="eye"/>
                            </CButton>
                          </td>
                        </template>
                      </dataTableExtended>
                    </CCardBody>
                  </CCollapse>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
    </div>
    <div v-show="showIndex==1">
      <PositioningRegister
        :Active="showIndex==1"
        :isEdit="isEdit"
        :PositionItem="PositionItem"
        @Close="(showIndex=0, PositionItem={}, isEdit=false)"
        @Update-list="UpdateData"
      />
    </div>
    <div v-show="showIndex==2">
      <ContainerPositioningRegister
        :Active="showIndex==2"
        :isEdit="isEdit"
        :PositionItem="PositionItem"
        @Close="(showIndex=0, PositionItem={}, isEdit=false)"
        @Update-list="UpdateData"
      />
    </div>
    <ModalSealImages
      :modal="ModalSeal"
      :labelModal="labelModalImages"
      :SealImages="SealImages"
      @Close="(ModalSeal=false, labelModalImages='', SealImages=[])"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import PositioningRegister from './positioning-register';
import ContainerPositioningRegister from '@/pages/yard-management/container/movements/container-positioning/positioning-register';
import ModalSealImages from '@/pages/yard-management/container/modal-seal-images';

//DATA-------------------------
function data() {
  return {
    collapseDuration: 0,
    showIndex: 0,
    tabIndex: 0,
    isEdit: false,
    ModalSeal: false,
    labelModalImages: '',
    SealImages: [],
    PositionItem: {},
    PackagingJson: [],
    ContainerJson: [],
  }
}

async function getYardEventPosition() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardEventPositionContainerPackaging-by-BlCargoId', { BlCargoId: this.BlCargoId??'', YardId: this.YardId??'' })
    .then(response => {
      let List = response.data.data?.[0];
      this.PackagingJson = List?.PackagingJson ?? [];
      this.ContainerJson = List?.ContainerJson ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(()=>{
      this.$store.state.yardManagement.loading = false;
    });
}

async function getBlCargoListYardId() {
  await this.$http.get('BlCargoResum-list-by-YardId', { BlCargoJson: {YardId: this.YardId} })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.globalSelectBl = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardCargo(item) {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        this.isEdit = true;
        this.showIndex = 2;
        this.PositionItem = item;
        this.$store.state.yardManagement.dataContainer = List[0];
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function handleTab(tab) {
  this.tabIndex = tab;
}

async function UpdateData() {
  this.$store.state.yardManagement.loading = true;
  await this.getBlCargoListYardId();
  await this.getYardEventPosition();
}

function viewDetail(item){
  item._toggled = !item._toggled;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
  });
}

function toggleAdd(item) {
  this.PositionItem = item;
  this.isEdit = true;
  this.showIndex = 1;
}

function toggleContainerAdd(item) {
  this.getYardCargo(item);
}

function ModalImagen(item) {
  this.labelModalImages = item.Seal;
  this.SealImages = item.SealDocumentJson;
  this.ModalSeal = true;
}

//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

function formatedItems() {
  let _lang = this.$i18n.locale;
  return this.PackagingJson.map((item, index) => {

    return {
      ...item,
      Nro: index+1,
      YardAreaName: item.YardAreaName ? item.YardAreaName : '',
      PackagingName: _lang=='en' ? item.PackagingNameEn : item.PackagingNameEs,
      Serial: item.Serial? item.Serial : '',
      YardNewDetailPos: item?.YardNewDetailPosJson?.map(NewDetailPos => {return NewDetailPos?.YardAreaName?.trim()})?.join(', ') ?? '',
      YardPreviousDetailPos: item?.YardPreviousDetailPosJson?.map(PreviousDetail => {return PreviousDetail?.YardAreaName?.trim()})?.join(', ') ?? '',
      ComputedQuantity: NumberFormater.formatNumber(item?.Quantity, 2),
      EventReasonName: _lang=='en' ? item.EventReasonNameEn : item.EventReasonNameEs,
      EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || '',
      DaysInYard: _lang=='en' ? item.DaysInYardEn || '' : item.DaysInYardEs || '',
      MachineCode: item.MachineCode ? item.MachineCode : '',
      ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
      ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
    };
  })
}

function formatedSeal(item) {
   return [{
        ...item,
        Seal1: item.Seal1 ? item.Seal1: '',
        Seal2: item.Seal2 ? item.Seal2: '',
        Seal3: item.Seal3 ? item.Seal3: '',
        Seal4: item.Seal4 ? item.Seal4: '',
  }];
}

function fields(){
  return [
    {
      key: 'button', 
      label: '', 
      _style: 'min-width:90px; width: 1%; text-align:center;', 
      sorter: false, 
      filter: false
    },
    { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { key: 'Nro', label: '#',_style: 'width: 5%', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'YardName',label: this.$t('label.ubication'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'YardAreaName', label: this.$t('label.module'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'YardPreviousDetailPos', label: this.$t('label.previousModule'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'YardNewDetailPos', label: this.$t('label.assignedModule'),  _style:'min-width: 180px;', _classes:"text-center" },
    { key: 'ComputedQuantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
    { key: 'PackagingName',label: this.$t('label.packaging'), _style:'min-width: 250px;', _classes: 'text-uppercase text-center'},
    { key: 'Serial',label: this.$t('label.serial'), _style:'min-width: 250px;', _classes: 'text-uppercase text-center'},
    { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'DeviceCod', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedInitDate', label: this.$t('label.startDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'ComputedFinishDate', label: this.$t('label.endDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'DaysInYard', label: this.$t('label.daysInYard'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px;', _classes:"text-center" }, 
  ];
}

function level2Fields(){
  return [
    { key: 'Seal1',label: `${this.$t('label.Seal')} 1`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'Seal2',label: `${this.$t('label.Seal')} 2`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'Seal3',label: `${this.$t('label.Seal')} 3`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'Seal4',label: `${this.$t('label.Seal')} 4`, _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
  ];
}

function Containerfields(){
  return [
    {
      key: 'button', 
      label: '', 
      _style: 'min-width:90px; width: 1%; text-align:center;', 
      sorter: false, 
      filter: false
    },
    { key: 'alertGif', label: 'ho', sorter: false, filter: false, _classes: 'text-center', _style: 'color: transparent; width:1%; text-align:center'  },
    { key: 'Nro', label: '#',_style: 'width: 5%', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'YardName',label: this.$t('label.ubication'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'YardAreaName', label: this.$t('label.module'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'YardPreviousDetailPos', label: this.$t('label.previousModule'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'YardNewDetailPos', label: this.$t('label.assignedModule'),  _style:'min-width: 180px;', _classes:"text-center" },
    { key: 'ComputedQuantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
    { key: 'PackagingName',label: this.$t('label.packaging'), _style:'min-width: 250px;', _classes: 'text-uppercase text-center'},
    { key: 'Serial',label: this.$t('label.serial'), _style:'min-width: 250px;', _classes: 'text-uppercase text-center'},
    { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'DeviceCod', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedInitDate', label: this.$t('label.startDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'ComputedFinishDate', label: this.$t('label.endDate'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'DaysInYard', label: this.$t('label.daysInYard'),  _style:'min-width: 130px;', _classes:"text-center" },
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px;', _classes:"text-center" }, 
  ];
}

function ConatinerItems() {
  let _lang = this.$i18n.locale;
  return this.dataReception.map((item, index) => {
    let NewDetailPos = item?.YardNewDetailPosJson ? 
      item.YardNewDetailPosJson
        .filter(NewDetailPos => process.env.VUE_APP_YARD_SLOT_STATUS==NewDetailPos.YardSlotStatus?.toUpperCase())
        .map(NewDetailPos =>NewDetailPos.SlotCode.trim()).join(', ') : '';
    let PreviousDetailPos = item?.YardPreviousDetailPosJson ? 
      item.YardPreviousDetailPosJson
        .filter(PreviousDetailPos => process.env.VUE_APP_YARD_SLOT_STATUS==PreviousDetailPos.YardSlotStatus?.toUpperCase())
        .map(PreviousDetailPos=> PreviousDetailPos.SlotCode.trim()).join(', ') : '';
    return {
      ...item,
      Nro: index+1,
      YardAreaName: item.YardAreaName ? item.YardAreaName : '',
      YardNewDetailPos: NewDetailPos,
      YardPreviousDetailPos: PreviousDetailPos,
      EventReasonName: _lang=='en' ? item.EventReasonNameEn : item.EventReasonNameEs,
      EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || '',
      DaysInYard: _lang=='en' ? item.DaysInYardEn || '' : item.DaysInYardEs || '',
      MachineCode: item.MachineCode ? item.MachineCode : '',
      ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
      ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
    };
  })
}

export default {
  name: 'bl-positioning-index',
  components: {
    CustomTabs,
    CustomTab,
    PositioningRegister,
    ContainerPositioningRegister,
    ModalSealImages,
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    getYardEventPosition,
    getBlCargoListYardId,
    getYardCargo,
    handleTab,
    UpdateData,
    viewDetail,
    toggleAdd,
    toggleContainerAdd,
    ModalImagen,
    formatedSeal
  },
  computed: {
    VisitOptions,
    fields,
    level2Fields,
    Containerfields,
    formatedItems,
    ConatinerItems,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      BlCargoId: state => state.yardManagement.dataBl.BlCargoId,
      YardId: state => state.yardManagement.yardData.YardId,
      GeneralList: state => state.yardManagement.GeneralList,
      dropBlMovement: state => state.yardManagement.dropBlMovement,
      dataContainer: state => state.containerSearch.dataContainer,
      FgPositioning: state => state.yardManagement.FgPositioning,
    })
  },
  mounted(){
    if (this.FgPositioning) {
      this.tabIndex = 0;
      this.PackagingJson = [];
      this.ContainerJson = [];
      this.getYardEventPosition();
    }
  },
  watch: {
    dropBlMovement: function (val) {
      if (val==2) {
        this.tabIndex = 0;
        this.PackagingJson = [];
        this.ContainerJson = [];
        this.getYardEventPosition();
      }
    }
  }
};
</script>